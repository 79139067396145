import React, { ReactElement, ReactNode, useMemo } from 'react'
import styled from '@emotion/styled'
import {
  FONT_14_BOLD,
  FONT_14,
  FONT_16_BOLD,
  STYLE_KEEP_WORD,
  STYLE_MULTILINE_CLAMP,
  TColors,
} from '@kakaomobility/tui-react-maas/dist/shuttle'
import { SalesStatus } from '@/services/models/common'
import { Hidden } from '@kakaomobility/tui-react'

interface ChannelingCardProps {
  title: string
  description: string
  price?: string
  status?: SalesStatus | undefined
  image: string | ReactNode
  onClick: () => void
  hasDescriptionClamp?: boolean
  availableBooking?: boolean
}
const Styled = {
  button: styled.button`
    position: relative;
    width: 100%;
    text-align: left;
    background: ${TColors.PRIMARY2};
    border-radius: 8px;
  `,

  image: styled.img`
    display: block;
    aspect-ratio: 3/2;
    width: 100%;
    object-fit: cover;
    border-radius: 8px 8px 0 0;
  `,
  body: styled.span`
    display: block;
    position: relative;
    padding: 12px 12px 10px;
    box-sizing: border-box;
    height: 122px;
  `,
  title: styled.strong`
    display: block;
    ${FONT_16_BOLD};
    ${STYLE_MULTILINE_CLAMP(2)};
  `,
  description: styled.span<{ hasDescriptionClamp: boolean }>`
    display: block;
    ${FONT_14};
    padding-top: 4px;
    color: ${TColors.NEUTRAL2};
    ${STYLE_KEEP_WORD};
    ${({ hasDescriptionClamp }) =>
      hasDescriptionClamp && STYLE_MULTILINE_CLAMP(1)};
  `,
  bottomPrice: styled.b<{ isPreparing: boolean }>`
    display: block;
    position: absolute;
    left: 12px;
    bottom: 10px;
    ${({ isPreparing }) => (isPreparing ? FONT_14 : FONT_14_BOLD)};
    color: ${({ isPreparing }) =>
      isPreparing ? TColors.NEUTRAL1 : TColors.PRIMARY1};
  `,
  bottomAvailableText: styled(AvailableReserveText)`
    position: absolute;
    right: 12px;
    bottom: 10px;
    ${FONT_14};
    color: ${TColors.NEUTRAL1};
  `,
}

function AvailableReserveText({
  availableBooking,
  className,
}: {
  availableBooking?: boolean
  className?: string
}): ReactElement | null {
  /**
   * @description 알파에서만 해당 문구 노출
   */
  if (availableBooking) return <div className={className}>예약 가능</div>
  else return <></>
}

export default function ShuttleCardList({
  status,
  price,
  title,
  description,
  image,
  onClick,
  availableBooking,
  hasDescriptionClamp = true,
}: ChannelingCardProps): ReactElement {
  const hiddenText = useMemo(() => {
    const mergedTitle = `${title} ${description}`
    if (mergedTitle.length < 20) return mergedTitle
    else return mergedTitle.slice(0, 21).concat('...중략...')
  }, [title, description])
  const isPreparing = status === SalesStatus.PREPARING
  return (
    <Styled.button type='button' onClick={onClick}>
      <>
        {typeof image === 'string' ? (
          <Styled.image src={image} alt='' />
        ) : (
          image
        )}
      </>
      <Styled.body>
        <Hidden>{hiddenText}</Hidden>
        <Styled.title aria-hidden>{title}</Styled.title>
        <Styled.description
          aria-hidden
          hasDescriptionClamp={hasDescriptionClamp}
        >
          {description}
        </Styled.description>
      </Styled.body>
      <Styled.bottomPrice isPreparing={isPreparing}>{price}</Styled.bottomPrice>
      <Styled.bottomAvailableText availableBooking={availableBooking} />
    </Styled.button>
  )
}
