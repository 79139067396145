import React, { ReactElement } from 'react'
import BottomSheet from '@/components/bottomSheet/BottomSheet'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { InjectedModalProps } from '@/plugins/modal'
import {
  FONT_16,
  FONT_16_BOLD,
  TColors,
} from '@kakaomobility/tui-react-maas/dist/shuttle'
import * as Icons from '@kakaomobility/tui-icons-react'
import { Hidden } from '@kakaomobility/tui-react'

const Styled = {
  Wrapper: styled.ul`
    width: 100%;
    padding: 8px 0;
  `,
  FilterItem: styled.li``,
  SelectButton: styled.button<{ selected: boolean }>`
    padding: 0 20px;
    width: 100%;
    display: flex;
    align-items: center;
    text-align: left;
    height: 56px;
    ${({ selected }) =>
      selected
        ? css`
            ${FONT_16_BOLD};
            color: ${TColors.PRIMARY1};
            &:after {
              content: '';
              display: block;
              width: 24px;
              height: 24px;
              margin-left: auto;
              background-image: url(${Icons.I24CheckOnUri});
            }
          `
        : css`
            ${FONT_16};
            color: ${TColors.NEUTRAL1};
          `}
  `,
}

interface SelectFilterBottomSheetProps extends InjectedModalProps {
  selectedValue: any
  optionList: Array<{ label: string; value: any }>
}

export default function SelectFilterBottomSheet({
  resolve,
  reject,
  optionList,
  selectedValue,
}: SelectFilterBottomSheetProps): ReactElement {
  const handleClose = (): void => {
    reject('close')
  }
  const handleSelect = async (
    option: typeof optionList[number]
  ): Promise<void> => {
    resolve(option)
  }

  return (
    <BottomSheet open onDismiss={handleClose}>
      <Styled.Wrapper>
        {optionList.map((option, index) => (
          <Styled.FilterItem key={`${index}-${option?.label}`}>
            <Styled.SelectButton
              key={option.label}
              type='button'
              selected={option.value === selectedValue}
              onClick={async () => await handleSelect(option)}
            >
              {option.value === selectedValue && <Hidden>선택 됨,</Hidden>}
              {option.label}
            </Styled.SelectButton>
          </Styled.FilterItem>
        ))}
      </Styled.Wrapper>
    </BottomSheet>
  )
}
