import {
  BottomSheet as TBottomSheet,
  BottomSheetProps,
} from '@kakaomobility/tui-react-maas/dist/maas'
import { ReactElement } from 'react'
import 'react-spring-bottom-sheet/dist/style.css'

export type { BottomSheetProps }
export default function BottomSheet(props: BottomSheetProps): ReactElement {
  return <TBottomSheet {...props} />
}
