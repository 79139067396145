/**
 * @description
 * img.jpg, img.png, ... 를 img_thumb.jpg 로 변환해주는 함수
 * 사용자가 img를 올리면 kage에서 자동적으로 상대적으로 작은 이미지를 생성해주는데 이 주소가 img_thumb.jpg로 끝남.
 */
const TENTH2_HOST = 'https://t1.kakaocdn.net/exterior/'
export const convertThumbImageUrl = (imgUrl?: string | null): string => {
  if (!imgUrl) return '' /* guard */
  if (typeof imgUrl !== 'string') return imgUrl
  if (imgUrl.startsWith(process.env.NEXT_PUBLIC_CDN_URI ?? TENTH2_HOST))
    return imgUrl
  return imgUrl?.replace(/img.*$/, 'img_thumb.jpg')
}
