import React, { ReactElement, useState } from 'react'
import styled from '@emotion/styled'
import { FONT_14, TColors } from '@kakaomobility/tui-react-maas/dist/shuttle'
import * as Icons from '@kakaomobility/tui-icons-react'
import { useRouter } from '@/router'
import { numberWithComma } from '@kakaomobility/tui-react-maas'
import { useModal } from '@/plugins/modal'
import SelectFilterBottomSheet from '@/components/bottomSheet/SelectFilterBottomSheet'

const Styled = {
  InfoPanel: styled.menu`
    padding: 2px 20px;
    display: flex;
    justify-content: space-between;
  `,
  TotalCount: styled.li`
    ${FONT_14};
    color: ${TColors.NEUTRAL2};
  `,
  Filter: styled.li`
    > button {
      display: flex;
      align-items: center;
      ${FONT_14};
      color: ${TColors.NEUTRAL2};
    }
  `,
  ArrowIcon: styled(Icons.I16ArrowDown)<{ open: boolean }>`
    margin-left: 2px;
    rotate: ${({ open }) => (open ? '180deg' : '0deg')};
  `,
}

interface TotalCountProps {
  totalCount?: number
}

function TotalCount({ totalCount }: TotalCountProps): ReactElement {
  return (
    <Styled.TotalCount>
      {numberWithComma(totalCount, '총 ', '개')}
    </Styled.TotalCount>
  )
}

export const ProductQueriesSort = {
  T_SHUTTLE_RECOMMEND: undefined,
  PRICE_DESC: 'PRICE_DESC',
  REGISTER_ASC: 'REGISTER_ASC',
} as const

const optionList = [
  {
    label: 'T셔틀 추천순',
    value: ProductQueriesSort.T_SHUTTLE_RECOMMEND,
  },
  {
    label: '가격 낮은순',
    value: ProductQueriesSort.PRICE_DESC,
  },
  {
    label: '최신 등록순',
    value: ProductQueriesSort.REGISTER_ASC,
  },
]

function FilterButton(): ReactElement {
  const router = useRouter()
  const modal = useModal()
  const [selectedOption, setSelectedOption] = useState(() => {
    return (
      optionList?.find((option) => option.value === router.query?.sort) ??
      optionList[0]
    )
  })
  const [open, setOpen] = useState(false)
  const handleClick = async (): Promise<void> => {
    try {
      setOpen(true)
      const option = (await modal.push(
        'SelectFilterBottomSheet',
        SelectFilterBottomSheet,
        { optionList, selectedValue: selectedOption?.value }
      )) as typeof optionList[number]
      const query = { ...router.query, sort: option.value }
      if (!query.sort) delete query.sort /* for query clean */
      await router.replace({
        query,
      })
      setSelectedOption(option)
    } catch (e) {
    } finally {
      setOpen(false)
    }
  }
  return (
    <Styled.Filter>
      <button type='button' onClick={handleClick} aria-live='assertive'>
        {selectedOption.label}
        <Styled.ArrowIcon open={open} />
      </button>
    </Styled.Filter>
  )
}

interface ProductInfoPanelProps {
  className?: string /* for emotion */
  totalCount?: number
}

export default function ProductInfoPanel({
  className,
  totalCount,
}: ProductInfoPanelProps): ReactElement {
  return (
    <Styled.InfoPanel className={className}>
      <TotalCount totalCount={totalCount} />
      <FilterButton />
    </Styled.InfoPanel>
  )
}
