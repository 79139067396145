import { PaginationReq, SalesStatus } from '@/services/models/common'
import { ShuttleMainInfo } from '@/services/models/bus-api/v1/item/display'
import instance from '@/services/instance'
import { FetcherInfiniteTupleConstructor } from '@/services/hooks/queryBuilder'
import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query'

/**
 * undefined - 최신 등록순
 * ticketprice - 가격 낮은순
 * score - T 셔틀 추천순
 */
export type ShuttleItemsSort = undefined | 'ticketprice' | 'score'

export interface GetShuttlesParams extends PaginationReq {
  salesStatus?: SalesStatus /* 판매 상태 */
  id?: string /* 상품 ID */
  name?: string /* 상품명 */
  tagname?: string /* 태그 ID */
  categoryId?: string | number /* 카테고리 ID */
  sort?: ShuttleItemsSort
}

export const getShuttles = async (
  params?: GetShuttlesParams
): Promise<ShuttleMainInfo> => {
  return (await instance.get(`/bus-api/v1/item/display`, { params }))?.data
}
export const getShuttleItemsInfiniteFetcherTuple: FetcherInfiniteTupleConstructor<
  ShuttleMainInfo,
  GetShuttlesParams
> = ({ params, options }) => [
  [
    '/v1/item/display',
    params?.id ?? '',
    params?.page ?? 0,
    params?.size ?? 0,
    params?.name ?? '',
    params?.tagname ?? '',
    params?.sort ?? '',
    params?.salesStatus ?? '',
  ],
  async ({ pageParam = 1 }) => {
    return await getShuttles({ ...params, page: pageParam })
  },
  {
    getNextPageParam: (lastPage) => {
      if (lastPage?.hasNext) return lastPage.page + 1
      else return undefined
    },
    ...(options ?? {}),
  },
]

export const useFetchInfiniteShuttleItems = ({
  params,
  options,
}: {
  params?: GetShuttlesParams
  options?: ReturnType<typeof getShuttleItemsInfiniteFetcherTuple>[2]
}): UseInfiniteQueryResult<ShuttleMainInfo> => {
  return useInfiniteQuery(
    ...getShuttleItemsInfiniteFetcherTuple({ params, options })
  )
}
