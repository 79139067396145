export type Day = '월' | '화' | '수' | '목' | '금' | '토' | '일'

export enum GoodsSeatType {
  FREE_SEAT = 'FREE_SEAT',
  RESERVED_SEAT = 'RESERVED_SEAT',
}

export enum ItemType {
  T_SHUTTLE_ITEM = 'T_SHUTTLE_ITEM',
}

export enum PromotionStatus {
  CLOSE = 'CLOSE',
  IN_PROGRESS = 'IN_PROGRESS',
  WAIT = 'WAIT',
}

export enum SalesStatus {
  ENDED = 'ENDED',
  ON_SALE = 'ON_SALE',
  PREPARING = 'PREPARING',
  SUSPENDED = 'SUSPENDED',
}

export enum ShuttleGrade {
  MINI = 'MINI',
  ORDINARY = 'ORDINARY',
  PREMIUM = 'PREMIUM',
}

export enum TravelScheduleItemType {
  ARRIVAL_STATION_LINK = 'ARRIVAL_STATION_LINK',
  DEPARTURE_STATION_LINK = 'DEPARTURE_STATION_LINK',
  PLACE_LINK = 'PLACE_LINK',
  PLAIN = 'PLAIN',
}

export interface PaginationReq {
  page?: number
  size?: number
}

export interface PaginationRes {
  numberOfElements: number /* 현재 페이지에 있는 데이터 수 */
  size: number /* 페이지 크기 */
  page: number /* 현제 페이지(1부터 시작) */
  isFirst: boolean /* 현제 페이지가 첫 페이지인지 여부 */
  isLast: boolean /* 현제 페이지가 마지막 페이지인지 여부 */
  hasNext: boolean /* 다음 페이지 존재 여부 */
  hasPrevious: boolean /* 이전 페이지 존재 여부 */
  hasContent: boolean /* 데이터 존재 여부 */
}

export enum ReservationStatus {
  REQUEST = 'REQUEST',
  CONFIRMED = 'CONFIRMED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
  BEFORE_PAY = 'BEFORE_PAY',
}

export enum TripType {
  ONE_WAY = 'ONE_WAY',
  ROUND_TRIP = 'ROUND_TRIP',
}

export enum SeatInventoryType {
  FREE = 'FREE',
  APPOINTED = 'APPOINTED',
}
export enum Vertical {
  T_SHUTTLE = 'T_SHUTTLE',
  CHARTER_BUS = 'CHARTER_BUS',
}

export enum TicketStatus {
  BOOKED = 'BOOKED',
  UNBOOKED = 'UNBOOKED',
}

export interface Location {
  latitude: number
  longitude: number
}

export enum SeatType {
  ORDINARY_45 = 'ORDINARY_45',
  ORDINARY_44 = 'ORDINARY_44',
  PREMIUM_28 = 'PREMIUM_28',
  PREMIUM_31 = 'PREMIUM_31',
  MINI_18 = 'MINI_18',
  MINI_12 = 'MINI_12',
}

export interface BusLine {
  /* require */
  order: number

  /* optional */
  departureBusStopName?: string
  departureDate?: string
  departureTime?: string
  arrivalBusStopName?: string
  arrivalDate?: string
  arrivalTime?: string
}

export interface CancelPolicy {
  /* required */
  version: number /* 취소정책 버전 */
  conditionType:
    | 'BASIC_TYPE' /* 기본조건 (취소 수수료 없음) */
    | 'ADDITIONAL_TYPE' /* 취소정책 추가조건 (수수료 있음) */
    | 'DISABLE_CANCEL_TYPE' /* 취소 불가 조건 기본조건(취소/환불 불가) */
  conditionValue: number /* 취소정책 조건 값 */
  conditionValueType: 'DAY' | 'HOUR' /* 조건 타입 - 일 단위, 시간 단위 */
  cancelFeeRatio: number /* 취소 수수료율 */
  /* optional */
  id?: number /* 취소 정책 ID */
  itemId?: number /* 상품 ID */
}
