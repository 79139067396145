import { UseInfiniteQueryResult } from 'react-query'
import {
  ShuttleDisplayCardInfo,
  ShuttleMainInfo,
} from '@/services/models/bus-api/v1/item/display'
import { PageShuttleMainQueries } from '@/router'
import {
  getShuttleItemsInfiniteFetcherTuple,
  useFetchInfiniteShuttleItems,
  GetShuttlesParams,
  ShuttleItemsSort,
} from '@/services/hooks/queries/shuttle-items/useFetchInfiniteShuttleItems'
import { FetcherInfiniteTupleConstructor } from '@/services/hooks/queryBuilder'
import React, { useEffect, useMemo } from 'react'
import * as Icons from '@kakaomobility/tui-icons-react'
import { ProductQueriesSort } from '@/components/shuttles/common/ProductInfoPanel'
import styled from '@emotion/styled'

const Styled = {
  DummyImage: styled.div`
    position: relative;
    aspect-ratio: 3/2;
    width: 100%;
    border-radius: 8px 8px 0 0;
    background: #d2e3ff;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
}

const dummyItem = {
  displayTitle: '곧 만나요',
  imageUrl: (
    <Styled.DummyImage>
      <Icons.I24ScheduleMapLink />
    </Styled.DummyImage>
  ),
  description: '더 많은 이동을 준비중입니다.',
} as unknown as ShuttleDisplayCardInfo

const defaultParams = {
  page: 1,
  size: 10,
  sort: 'score',
} as const

export const createInfiniteHomeParams = (
  query?: PageShuttleMainQueries
): GetShuttlesParams => {
  const params = {
    page: defaultParams.page,
    size: defaultParams.size,
    sort: (() => {
      switch (query?.sort) {
        case ProductQueriesSort.PRICE_DESC:
          return 'ticketprice'
        case ProductQueriesSort.REGISTER_ASC:
          return null
        default:
          return 'score'
      }
    })() as unknown as ShuttleItemsSort,
    categoryId: query?.categoryId,
  }
  return params
}

export const getInfiniteHomeItemsFetcherTuple = (
  query?: PageShuttleMainQueries
): ReturnType<FetcherInfiniteTupleConstructor<ShuttleMainInfo>> => {
  const params = createInfiniteHomeParams(query)
  return getShuttleItemsInfiniteFetcherTuple({ params })
}

export const useFetchInfiniteHomeItems = (
  query?: PageShuttleMainQueries
): UseInfiniteQueryResult<ShuttleMainInfo> & {
  items: ShuttleDisplayCardInfo[]
  totalCount: number
} => {
  const params = useMemo(() => createInfiniteHomeParams(query), [query])
  const queryResult = useFetchInfiniteShuttleItems({
    params,
  })

  useEffect(() => {
    queryResult.refetch()
  }, [queryResult.refetch, query?.sort, query?.categoryId, query?.page])

  const items = useMemo(() => {
    const allContents = queryResult.data?.pages?.reduce<
      ShuttleDisplayCardInfo[]
    >((acc, cu) => {
      return [...acc, ...(cu?.content ?? [])]
    }, [])

    if (!allContents?.length) return [] /* guard */

    const isLast =
      queryResult.data?.pages?.[queryResult?.data?.pages?.length - 1]?.isLast
    if (isLast && allContents?.length % 2 !== 0) allContents.push(dummyItem)
    return allContents
  }, [queryResult.data])

  const totalCount = useMemo(
    () => queryResult.data?.pages?.[0]?.totalCount ?? 0,
    [queryResult.data]
  )

  return {
    ...queryResult,
    items,
    totalCount,
  }
}
